<script>
import {authFackMethods, authMethods} from "@/state/helpers";
// import appConfig from "@/app.config";

import {required, email} from "vuelidate/lib/validators";

/**
 * Forgot Password component
 */
export default {
    page: {
        title: "Reset Password",
        /*meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],*/
    },
    components: {},
    data() {
        return {
            email: "",
            model: {},
            submitted: false,
            error: null,
            tryingToReset: false,
            isResetError: false,
        };
    },
    validations: {
        email: {
            required,
            email,
        },
    },
    methods: {
        ...authMethods,
        ...authFackMethods,
        // Try to register the user in with the email, fullname
        // and password they provided.
        tryToReset() {
            this.submitted = true;
            // stop here if form is invalid
            this.$store.dispatch('auth/resetPassword', Object.assign({hash: this.$route.params?.hash}, this.model))
        },
    },
};
</script>

<template>
    <div class="authentication-bg min-vh-100">
        <div class="bg-overlay bg-white"></div>
        <div class="container">
            <div class="d-flex flex-column min-vh-100 px-3 pt-4">
                <div class="row justify-content-center my-auto">
                    <div class="col-md-8 col-lg-6 col-xl-4">
                        <div class="text-center py-5">
                            <div class="mb-4 mb-md-5">
                                <router-link class="d-block auth-logo" to="/">
                                    <img
                                        alt=""
                                        class="auth-logo-dark"
                                        height="22"
                                        src="@/assets/images/logo-dark.png"
                                    />
                                    <img
                                        alt=""
                                        class="auth-logo-light"
                                        height="22"
                                        src="@/assets/images/logo-light.png"
                                    />
                                </router-link>
                            </div>
                            <div class="text-muted mb-4">
                                <h5 class="">{{ $t('login.forgot_password') }}</h5>
                                <p>{{ $t('login.reset_password_for_existing_account') }}</p>
                            </div>
                            <b-alert
                                v-model="isResetError"
                                class="mb-4"
                                dismissible
                                variant="danger"
                            >{{ error }}
                            </b-alert
                            >
                            <form class="form" @submit.prevent="tryToReset">
                                <div class="mb-4">
                                    <div class="form-floating form-floating-custom mb-3">
                                        <input type="password" class="form-control" id="input-newpassword" v-model="model.password" :placeholder="$t('profile.placeholders.new_password')">
                                        <label for="input-newpassword">{{ $t('profile.new_password') }}</label>
                                        <div class="form-floating-icon">
                                            <i class="uil uil-padlock"></i>
                                        </div>
                                    </div>
                                    <div class="form-floating form-floating-custom mb-3">
                                        <input type="password" class="form-control" id="input-confirmpassword" v-model="model.password_re" :placeholder="$t('profile.placeholders.repeat_new_password')">
                                        <label for="input-confirmpassword">{{ $t('profile.repeat_new_password') }}</label>
                                        <div class="form-floating-icon">
                                            <i class="uil uil-check-circle"></i>
                                        </div>
                                    </div>

                                </div>

                                <div class="mt-3">
                                    <b-button block class="w-100" variant="info" @click="tryToReset">
                                        {{ $t('login.update_password') }}
                                    </b-button>
                                </div>
                                <div class="mt-3">
                                    <router-link
                                        class="text-muted text-decoration-underline"
                                        to="/login"
                                    >{{ $t('login.log_in') }}
                                    </router-link>
                                </div>
                            </form>
                            <!-- end form -->
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->

                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center text-muted p-4">
                            <p class="mb-0">
                                &copy; {{ new Date().getFullYear() }} SimpleSMS. {{ $t('login.crafted_with') }}
                                <i class="mdi mdi-heart text-danger"></i> by APPON.hu
                            </p>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
        </div>
        <!-- end container -->
    </div>
    <!-- end authentication section -->
</template>

<style lang="scss" module></style>
